import axios from 'axios';
import Vue from 'vue';
import Cookies from 'js-cookie';
import store from '@/store';
import router from '@/router';
import apiConfig from './api.config';
import { token } from 'utils/localkey';
/**
 * !!!注意 action 是自己设置的变量打包后 这个属性不会存在
 */
// 配置接口错误黑名单
const apiErrorBlackList = [];
const downFiles = [];
const ignoreTipCode = [4400]; // 不吐司弹窗code(接口响应的code),如：需要不同的code做不同的业务处理时，无需弹窗提示
const vm = Vue.prototype;
let modalErr = null;
const AXIOS = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? '' : window.location.origin,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000 // 车场问题超时延长至15秒
});

// 请求列表(防重复提交)
const requestList = [];
const CancelToken = axios.CancelToken;

AXIOS.interceptors.request.use(
  (config) => {
    // 开发模式开启代理
    if (process.env.NODE_ENV !== 'production') {
      // 记录action
      config.action = config.url;
      if (!/http/.test(config.url)) {
        config.url = `/apiProxy/${config.url}`;
      }
      config.inParams = config.params || config.data || {};
    }
    config.headers.token = localStorage.getItem(token) || '';

    // 防止重复提交（如果本次是重复操作，则取消，否则将该操作标记到requestList中）
    config.cancelToken = new CancelToken((cancel) => {
      let requestFlag =
        config.url + JSON.stringify(config.data) + '&' + config.method;
      if (requestList.includes(requestFlag)) {
        // 请求标记已经存在，则取消本次请求，否则在请求列表中加入请求标记
        cancel(); // 取消本次请求
      } else {
        requestList.push(requestFlag);
      }
    });

    return config;
  },
  (error) => {
    console.log('request-error :', error);
    return Promise.reject(error);
  }
);
/*
  添加响应拦截器
  http状态码为200的时候判断
  1. SUCCESS(2000,"成功"),
  2. NO_LOGIN(3000,"未登录重定向"),
  3. ERROR_SYSTEM_CODE(4000,"系统异常！"),
  4. SYSTEM_ERROR(5000,"异常直接提示")
 */
AXIOS.interceptors.response.use(
  (res) => {
    removeResponseApi(res);
    const data = res.data;
    if (data && res.status === 200) {
      // 兼容 接口code 和 msg 不统一 ---start
      const { code, msg } = data;
      const dataObj = data.data;
      data.data =
        data.data || dataObj === null || dataObj === 0 || dataObj === false
          ? dataObj
          : []; // 有的接口数据为空时，没有data字段，防止报错，做此兼容
      // token过期
      if (+data.code === 401 || +data.code === 402) {
        localStorage.removeItem(token);
        store.commit('user/resetUser');
        router.replace('/login');
      }
      //   else if (+data.resultCode === 403) {
      //     const msg = '权限不足，请您联系管理员';
      //     vm.$msg.closeAll();
      //     vm.$msg.warn(msg);
      //     return Promise.reject(new Error(msg));
      //   }

      return data;
    }
    return Promise.reject(res);
  },
  (error) => {
    removeResponseApi(error);
    // 判断黑名单接口，不对黑名单的接口进行接口错误处理
    console.log(error.config.url, 'error.config.url');
    if (
      apiErrorBlackList.find((item) => error.config.url.indexOf(item) !== -1)
    ) {
    } else {
      // 显示统一错误页
      let errMsg = '';
      if (error.message.indexOf('timeout') !== -1) {
        errMsg = '连接网络超时，稍后再试';
      } else if (error.message.indexOf('Network Error') !== -1) {
        errMsg = '您的网络好像断开了哦~';
      } else {
        errMsg = '服务器返回失败/请求接口错误';
      }
      vm.$msg.closeAll();
      vm.$msg.error(errMsg);
    }
    return Promise.reject(error);
  }
);

// 目前只有get和post
const request = () => ({
  get(path, data = {}, config = {}) {
    return AXIOS.get(path, {
      params: data,
      ...config
    })
      .catch((error) => {
        return {
          code: 4400,
          data: null,
          msg: 'catch error',
          error
        };
      })
      .then(toastHandler);
  },
  post(path, data = {}, config = {}) {
    return AXIOS.post(path, data, config)
      .catch((error) => {
        return {
          code: 4400,
          data: null,
          msg: 'catch error',
          error
        };
      })
      .then(toastHandler);
  }
});
/**
 * @des 统一吐司接口code非2000时的msg
 */
const toastHandler = (res) => {
  let { code, msg } = res;
  if (code !== 200 && !ignoreTipCode.includes(code)) {
    // 只有code为501，才直接将接口返回的msg直接提示（和后端约定）。否则都转义再弹窗。
    if (code === 401) {
      msg = 'token过期';
    } else if (code === 402) {
      //   msg = '账号在其他地方登录'; 先用后端的msg
    } else if (code === 600) {
      msg = '您的额度已用完';
    }
    vm.$msg.closeAll(); // 防止多条消息同时显示
    vm.$msg.error(msg);
  }
  return res;
};

/**
 * @des  请求响应后，将请求标记从requestList中移除
 */
const removeResponseApi = (res) => {
  let requestFlag =
    res.config.url + JSON.stringify(res.config.data) + '&' + res.config.method;
  requestList.splice(
    requestList.findIndex((item) => item === requestFlag),
    1
  );
};
export default request();
