// import { getUserInfo } from 'api/login';
const state = () => ({
  title: '',
  hasAddcRoute: false // 是否动态添加过了权限路由
});

const getters = {};

const mutations = {
  setTitle: (state, title) => {
    state.title = title;
    document.title = title;
  },
  setHasAddcRoute: (state, isB) => {
    state.hasAddcRoute = isB;
  },
  resetGlobal: (state) => {
    state.hasAddcRoute = false;
  }
};

const actions = {
  //   async getUserInfo({ commit }) {
  //     const { code, data } = await getUserInfo();
  //     if (code === 200) {
  //       commit('setUserInfo', data);
  //     }
  //   }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
