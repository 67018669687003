import { getUserInfo } from 'api/login';
import { Message } from 'element-ui';
const state = () => ({
  userInfo: null // 用户信息
});

const getters = {};

const mutations = {
  setUserInfo: (state, info) => {
    state.userInfo = info;
  },
  resetUser: (state) => {
    state.userInfo = {};
  }
};

const actions = {
  async getUserInfo({ commit }) {
    const { code, data } = await getUserInfo();
    if (code === 200) {
      const { check, status } = data;
      if (+check !== 1 || +status !== 1) {
        Message.error('账号异常');
        localStorage.removeItem('token');
        return;
      }
      commit('setUserInfo', data);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
