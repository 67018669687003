import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiConfig from '@/lib/js/api.config';
import {
  Button,
  Select,
  Option,
  Loading,
  Message,
  Icon,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Form,
  FormItem,
  MessageBox,
  Popconfirm,
  Popover,
  Tag,
  Card,
  Tree,
  Upload,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Radio,
  RadioGroup,
  Transfer,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Empty,
  Menu,
  MenuItem,
  Cascader
} from 'element-ui';
import VueClipboard from 'vue-clipboard2';

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }; // element ui 的全局配置
Vue.use(Button)
  .use(Select)
  .use(Option)
  .use(Icon)
  .use(Tooltip)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Input)
  .use(FormItem)
  .use(Popconfirm)
  .use(Popover)
  .use(Tag)
  .use(Card)
  .use(Tree)
  .use(Upload)
  .use(Tabs)
  .use(TabPane)
  .use(Table)
  .use(TableColumn)
  .use(Loading)
  .use(Pagination)
  .use(Dialog)
  .use(Form)
  .use(FormItem)
  .use(Radio)
  .use(RadioGroup)
  .use(Transfer)
  .use(VueClipboard)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Empty)
  .use(DatePicker)
  .use(Menu)
  .use(MenuItem)
  .use(Cascader);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msg = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$alert = MessageBox.alert;

Vue.prototype.$myEnv = process.env.MY_ENV;
const isDev = process.env.NODE_ENV !== 'production';
Vue.prototype.$apiHost = isDev ? 'apiProxy' : window.location.origin;

Vue.config.productionTip = false;

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
