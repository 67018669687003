import request from 'lib/js/request';
const login = (data) => request.post('api/modules/login', data);
const register = (data) => request.post('api/modules/sign', data);
const getUserInfo = (data) => request.get('api/modules/user', data);
const get_phone_verifycode = (data) =>
  request.post('api/modules/get_phone_verifycode', data);
const login_by_phone_password = (data) =>
  request.post('api/modules/login_by_phone_password', data);
const login_by_phone_verifycode = (data) =>
  request.post('api/modules/login_by_phone_verifycode', data);
export {
  login,
  register,
  getUserInfo,
  get_phone_verifycode,
  login_by_phone_password,
  login_by_phone_verifycode
};
