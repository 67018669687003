const config = (myenv) => {
  let apiHost = '';
  switch (myenv) {
    // 开发环境 dev，测试环境 test
    case 'dev':
    case 'test':
      //   apiHost = 'http://43.243.130.132:7894';
      apiHost = 'https://oa.test.ai.wy.cn';
      break;
    case 'prod':
      //   apiHost = 'http://43.243.130.132:7894';
      apiHost = 'https://oa.ai.wy.cn';
      break;
    // 默认为开发/测试 环境
    default:
      apiHost = 'https://oa.ai.wy.cn';
      //   apiHost = 'http://43.243.130.132:7894';
      break;
  }
  return apiHost;
};

module.exports = config;
