const getters = {
  userRole: (state) => state.user.userInfo.user_type,
  isManager: (state) => state.user.userInfo.user_type === 'manager',
  isAdmin: (state) => state.user.userInfo.user_type === 'admin',
  isSuperManager: (state) => state.user.userInfo.user_type === 'super_manager',
  isManagers: (state) => {
    const role = state.user.userInfo.user_type;
    return role === 'manager' || role === 'super_manager';
  },
  isAM: (state) => {
    const role = state.user.userInfo.user_type;
    return role === 'manager' || role === 'admin' || role === 'super_manager';
  },
  isUser: (state) => state.user.userInfo.user_type === 'user'
};
export default getters;
