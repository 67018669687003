// 是否未账号（字母、数字、特殊符号组成）
export const isAccount = (val) => {
  return /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(val);
};
// 是否为密码的格式（6位数的密码，字母+数字+特殊符号）
export const isPwd = (val) => {
  return /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,}$/.test(val);
};

// 是否手机号
export const isPhone = (val) => {
  return /^1[3456789]\d{9}$/.test(val);
};

// 是否全部都是空格
export const isSpaceBlank = (val) => {
  return /^\s*$/.test(val);
};
