import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeLayout from '@/layout/home/HomeLayout.vue';
import ConsoleLayout from '@/layout/console/ConsoleLayout.vue';
import { token } from 'utils/localkey';
import store from '../store';

Vue.use(VueRouter);
// 免token可以访问的页面
const whiteRouterNameList = ['Login', 'Register', '404', 'Feedback'];

const routes = [
  {
    path: '/',
    redirect: '/console'
  },
  {
    path: '',
    component: ConsoleLayout,
    children: [
      {
        path: '/console',
        name: 'Console',
        component: () =>
          import(
            /* webpackChunkName: "Console" */ '../pages/console/Console.vue'
          ),
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    path: '',
    component: HomeLayout,
    children: [
      {
        path: '/aiChat',
        name: 'AiChat',
        component: () =>
          import(/* webpackChunkName: "Chat" */ '../pages/chat/AiChat.vue'),
        meta: {
          title: '智能聊天'
        }
      },
      {
        path: '/knowledgeChat',
        name: 'KnowledgeChat',
        component: () =>
          import(
            /* webpackChunkName: "Chat" */ '../pages/chat/KnowledgeChat.vue'
          ),
        meta: {
          title: '知识库问答'
        }
      },
      {
        path: '/knowledgeManage',
        name: 'KnowledgeManage',
        component: () =>
          import(
            /* webpackChunkName: "knowledgeManage" */ '../pages/knowledgeManage/KnowledgeManage.vue'
          ),
        meta: {
          title: '知识库管理'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../pages/login/Login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "login" */ '../pages/login/Register'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/viewDoc',
    name: 'ViewDoc',
    component: () =>
      import(/* webpackChunkName: "login" */ '../pages/viewDoc/ViewDoc'),
    meta: {
      title: '知识库类型'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/404/404'),
    meta: {
      title: '404'
    }
  }
];
// admin权限的路由
const adminRoute = [
  {
    path: '/businessManage',
    name: 'BusinessManage',
    component: () =>
      import(
        /* webpackChunkName: "BusinessManage" */ '../pages/businessManage/BusinessManage.vue'
      ),
    meta: {
      title: '企业管理'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(
        /* webpackChunkName: "BusinessManage" */ '../pages/feedback/Feedback.vue'
      ),
    meta: {
      title: '用户反馈'
    }
  }
];
// manager权限的路由
const managerRoute = [
  {
    path: '/staffManage',
    name: 'StaffManage',
    component: () =>
      import(
        /* webpackChunkName: "StaffManage" */ '../pages/staffManage/StaffManage.vue'
      ),
    meta: {
      title: '员工管理'
    }
  },
  {
    path: '/modelQuotaManage',
    name: 'ModelQuotaManage',
    component: () =>
      import(
        /* webpackChunkName: "ModelQuotaManage" */ '../pages/modelQuotaManage/ModelQuotaManage.vue'
      ),
    meta: {
      title: '企业额度'
    }
  }
];
const base = {
  development: '/knowledgeFront/',
  production: '/knowledgeFront/'
};

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: base[process.env.NODE_ENV],
    routes
  });
const router = createRouter();
router.beforeEach(async (to, from, next) => {
  const { name: toName } = to;
  const isLogin = localStorage.getItem(token);
  // 已登录状态
  if (isLogin) {
    // 判断vuex是否有用户信息
    const userInfo = store.state.user.userInfo;
    if (!userInfo || !userInfo.user_type) {
      await store.dispatch('user/getUserInfo');
    }
    const hasAddRoute = store.state.global.hasAddcRoute; // 是否动态添加过了权限路由
    if (!hasAddRoute) {
      store.commit('global/setHasAddcRoute', true);
      let fullRoute = [];
      const userType = store.state.user.userInfo?.user_type; // 用户角色
      console.log(userType, 'userType');
      if (userType === 'admin') {
        fullRoute = adminRoute;
      } else if (userType === 'manager' || userType === 'super_manager') {
        fullRoute = managerRoute;
      }

      resetRouter();
      fullRoute.forEach((route) => {
        router.addRoute({
          path: '',
          component: ConsoleLayout,
          children: [route]
        });
      });
      fullRoute.push({
        path: '*',
        redirect: '/404'
      });

      // 异步路由需要这样写法，否则动态路由页面刷新会出现白屏
      next({ ...to, replace: true });
    }

    if (toName === 'Login') {
      next('/');
      return;
    }
    next();
  } else {
    if (whiteRouterNameList.includes(toName)) {
      next();
    } else {
      next('/login');
    }
  }
});
router.beforeResolve((to, from, next) => {
  /* 路由发生变化修改页面title */
  const title = to.meta.title;
  if (title) {
    store.commit('global/setTitle', title);
  }
  next();
});

// vuex数据的重置
function resetStore() {
  store.commit('global/resetGlobal');
  store.commit('user/resetUser');
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// 处理 vue-route 报错 Uncaught (in promise) undefined
const routerMethods = ['push', 'replace'];
routerMethods.forEach((method) => {
  const originalCall = VueRouter.prototype[method];
  VueRouter.prototype[method] = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalCall.call(this, location, onResolve, onReject);
    }
    return originalCall.call(this, location).catch((err) => err);
  };
});
export default router;
