<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import { token } from '@/utils/localkey';
export default {
    data() {
        return {
            routeNames: ['Login', 'Register']
        };
    },
    mounted() {
        // 浏览器tags切换，账号检测
        const tk = localStorage.getItem(token);
        tk && sessionStorage.setItem(token, tk);
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                const { name } = this.$route;
                const tk = localStorage.getItem(token);
                const sessionTk = sessionStorage.getItem(token);
                if (!tk) {
                    sessionStorage.removeItem(token);
                }
                if (tk && sessionTk && sessionTk !== tk) {
                    this.$alert(
                        '系统检测到用户账号发生变更，确定刷新页面？',
                        {
                            confirmButtonText: '确定',
                            type: 'warning',
                            showClose: false,
                            'show-cancel-button': false,
                            'close-on-press-escape': false,
                            'close-on-click-modal': false
                        }
                    )
                        .then(() => {
                            window.location.reload();
                        });
                    return;
                }
                if (!tk && !this.routeNames.includes(name)) {
                    this.$router.replace('/login');
                }
                tk && sessionStorage.setItem(token, tk);
            }
        });
    }
};
</script>

<style lang="less">
@import url('lib/styles/basic.css');
@import url('lib/styles/mui.less');
#app {
    min-width: 1170px;
}
* {
    //   color: @PrimaryText;
    // font-size: 14px;
    box-sizing: border-box;
}
// 修改全局placeholder的颜色
::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: @Placeholder;
}
</style>
